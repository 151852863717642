import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import type { NavigateFunction } from 'react-router-dom';
import {
  Button,
  Card,
  Collapse,
  Dialog,
  Form,
  Image,
  Input,
  Popup,
  Space
} from 'antd-mobile';
import { InformationCircleOutline } from 'antd-mobile-icons';
import * as api from '../api';
import mobileApi from '../api/mobileApi';

const Home: React.FC = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [requesting, setRequesting] = useState<boolean>(false);
  const [currentUser, setCurrentUser] = useState<any>({});
  const [visible, setVisible] = useState<boolean>(false);
  const navigate: NavigateFunction = useNavigate();
  let lastRequestTime: number = 0;

  const getCurrentUser = async () => {
    const currentTime: number = new Date().getTime();
    if (loading || currentTime - lastRequestTime < 50) {
      return;
    }

    lastRequestTime = currentTime;
    setLoading(true);
    const res: any = await api.GET('/api/currentUser', null, null);
    const data: any = res.success ? res.data : {};
    setCurrentUser(data);
    setLoading(false);
  };

  const submitForm = (values: any) => {
    if (requesting) {
      return;
    }

    setRequesting(true);
    mobileApi.login(values.username, values.password).then((res: any) => {
      setRequesting(false);
      if (res.code === 200) {
        setCurrentUser(res.data);
        setVisible(false);
        navigate('/replay');
      } else {
        Dialog.alert({
          content: res.message || '登录失败',
          confirmText: '确定'
        });
      }
    });
  };

  useEffect(() => {
    getCurrentUser();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <Space
        align="center"
        direction="vertical"
        justify="center"
        className="home-container"
      >
        <Image
          src="/images/mobile-banner.jpg"
          fit="fill"
          className="banner"
        />
        <Card
          className="audio-card"
          onBodyClick={() => navigate('/audio')}
        >
          <p>《9988雅思听力真题答案词汇》</p>
          <p>听力答案词 & 听力替换词</p>
          <p>配套音频 收听</p>
          <p>（免费）</p>
        </Card>
        <Card
          className="replay-card"
          onBodyClick={() => {
            if (currentUser.id) {
              navigate('/replay');
            } else {
              setVisible(true);
            }
          }}
        >
          <p>《9988雅思听力真题答案词汇》</p>
          <p>第2章 听力答案词</p>
          <p>听写软件服务</p>
          <p>错词复盘</p>
        </Card>
      </Space>
      <Popup
        visible={visible}
        showCloseButton={!requesting}
        className="login-popup"
        onClose={() => setVisible(false)}
      >
        <Form
          name="form"
          disabled={requesting}
          footer={
            <Button
              block
              type="submit"
              color="primary"
              size="large"
              loading={requesting}
            >
              登录
            </Button>
          }
          onFinish={values => submitForm(values)}
        >
          <Form.Header>登录</Form.Header>
          <Form.Item
            name="username"
            label="用户名 / 手机"
            rules={[{
              required: true,
              message: '用户名 / 手机不能为空'
            }]}
          >
            <Input
              autoComplete="off"
              type="text"
              placeholder="请输入用户名或手机"
            />
          </Form.Item>
          <Form.Item
            name="password"
            label="密码"
            rules={[{
              required: true,
              message: '密码不能为空'
            }]}
          >
            <Input
              autoComplete="off"
              type="password"
              placeholder="请输入密码"
            />
          </Form.Item>
          <Collapse defaultActiveKey={['1']}>
            <Collapse.Panel
              key="1"
              title={
                <>
                  <InformationCircleOutline />
                  提示
                </>
              }
              className="prompt-panel"
            >
              <p>
                “错词复盘”功能需要调用你在“9988雅思听力答案词听写软件服务”上的听写记录（该软件服务目前收费）。请用电脑浏览器登录软件网站www.ielts9988.com注册并提交听写结果，再进行错词复盘。
              </p>
            </Collapse.Panel>
          </Collapse>
        </Form>
      </Popup>
    </>
  );
};

export default Home;
